body {
    background-color: black;
    color: white;
    margin: 0;
    font-family: Arial, sans-serif;
}

#root {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 90vh;
    padding: 20px;
}

h1, h2, h3, p {
    margin: 0;
    padding: 5px 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    padding: 5px 0;
}

.section {
    border-top: 3px solid white;
    width: 95%;
    padding-bottom: 25px;
}

.weather-table th, td {
  padding-right: 15px;  
  text-align: center;
}

.weather-header {
  font-size: 7em;
  text-align: center;
}

.clock {
  text-align: center;
}


.clock h1 {
  font-size: 7em;  
}

.quote h1 {
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 1.5em;
}

.quote h2 {
  font-style: italic;
  text-align: center;
  font-size: 1em;
}

.content {
  width: 50%;
}

.last-updated {
  font-style: italic;
  font-size: 0.8em;
}

.elizabeth {
  background-color: rgb(96, 57, 158);
}

.mildmay {
  background-color: #0056c7;
}

.piccadilly {
  background-color: #0019a8;
}

.district {
  background-color: #007d32;
}